@charset "UTF-8";
@import url("//fonts.maateen.me/kalpurush/font.css");
.dark-red-text {
  color: #8C2E24; }

.violate-text {
  color: #6A3394; }

.dark-gray-bg {
  background-color: #49505b; }

.light-gray-bg {
  background-color: #d5d5d5; }

.pink-bg {
  background-color: #F0008B; }

.light-orange-bg {
  background-color: #D8B386; }

.creamy-bg {
  background-color: #EAECE9; }

.overflow-hidden {
  overflow: hidden; }

.gray-border {
  border: 1px solid #49505b; }

.green-overline {
  border-top: 1px solid #008e47; }

.light-green-bg {
  background-color: #A1CF86; }

/** PULL **/
.no-pull-top {
  padding-top: 0px; }

.no-pull-bottom {
  padding-bottom: 0px; }

.no-pull-left {
  padding-left: 0px; }

.no-pull-right {
  padding-right: 0px; }

.pull-up-10 {
  padding-top: 10px; }

.pull-down-10 {
  padding-bottom: 10px; }

.pull-left-10 {
  padding-left: 10px; }

.pull-right-10 {
  padding-right: 10px; }

.pull-up-5 {
  padding-top: 5px; }

.pull-down-5 {
  padding-bottom: 5px; }

.pull-left-5 {
  padding-left: 5px; }

.pull-right-5 {
  padding-right: 5px; }

.pull-up-15 {
  padding-top: 15px; }

.pull-down-15 {
  padding-bottom: 15px; }

.pull-left-15 {
  padding-left: 15px; }

.pull-right-15 {
  padding-right: 15px; }

.pull-up-20 {
  padding-top: 20px; }

.pull-down-20 {
  padding-bottom: 20px; }

.pull-left-20 {
  padding-left: 20px; }

.pull-right-20 {
  padding-right: 20px; }

.pull-up-50 {
  padding-top: 50px; }

.pull-down-50 {
  padding-bottom: 50px; }

.pull-left-50 {
  padding-left: 50px !important; }

.pull-right-50 {
  padding-right: 50px; }

.pull-5 {
  padding: 5px; }

.pull-10 {
  padding: 10px; }

.pull-20 {
  padding: 20px; }

.no-pull {
  padding: 0px; }

/** END: PULL **/
/** PUSH **/
.no-push {
  margin: 0px; }

.push-down--5 {
  margin-bottom: -5px !important; }

.push-5 {
  margin: 5px; }

.push-10 {
  margin: 10px; }

.push-20 {
  margin: 20px; }

.push-up-10 {
  margin-top: 10px; }

.push-down-10 {
  margin-bottom: 10px; }

.push-left-10 {
  margin-left: 10px; }

.push-right-10 {
  margin-right: 10px; }

.push-up-80 {
  margin-top: 80px; }

.push-up-5 {
  margin-top: 5px; }

.push-down-5 {
  margin-bottom: 5px; }

.push-left-5 {
  margin-left: 5px; }

.push-right-5 {
  margin-right: 5px; }

.push-up-15 {
  margin-top: 15px; }

.push-down-15 {
  margin-bottom: 15px; }

.push-left-15 {
  margin-left: 15px; }

.push-right-15 {
  margin-right: 15px; }

.push-up-20 {
  margin-top: 20px; }

.push-down-20 {
  margin-bottom: 20px; }

.push-left-20 {
  margin-left: 20px; }

.push-right-20 {
  margin-right: 20px; }

.push-up-50 {
  margin-top: 50px; }

.push-down-50 {
  margin-bottom: 50px; }

.push-left-50 {
  margin-left: 50px; }

.push-right-50 {
  margin-right: 50px; }

.push-up-100 {
  margin-top: 100px; }

/** END: PUSH **/
/** HEIGHT **/
.height-50 {
  height: 50px; }

.height-100 {
  height: 100px; }

.height-150 {
  height: 150px; }

.height-200 {
  height: 200px; }

.height-300 {
  height: 300px; }

.height-400 {
  height: 400px; }

.height-500 {
  height: 500px; }

.height-600 {
  height: 600px; }

.min-height-50 {
  min-height: 50px; }

.min-height-100 {
  min-height: 100px; }

.min-height-150 {
  min-height: 150px; }

.min-height-200 {
  min-height: 200px; }

.min-height-300 {
  min-height: 300px; }

.min-height-400 {
  min-height: 400px; }

.min-height-500 {
  min-height: 500px; }

.min-height-600 {
  min-height: 600px; }

/** END : HEIGHT**/
/** TEXT COLOR **/
.white-text {
  color: #ffffff; }

.black-text {
  color: #1b2432; }

.blue-text {
  color: #0364B1; }

.navy-text {
  color: #AAD5F7; }

.green-text {
  color: #008e47; }

.orange-text {
  color: #EA862E; }

.red-text {
  color: #E9201F; }

.gray-text {
  color: #49505b; }

.lightGray-text {
  color: #d5d5d5; }

.offwhite-text {
  color: #f5f5f5; }

.yellow-text {
  color: #ffb900; }

/** END: TEXT COLOR **/
/** BACKGROUND COLOR **/
.whitish-bg {
  background-color: rgba(255, 255, 255, 0.5); }

body.white-bg .whitish-bg {
  background-color: rgba(3, 100, 177, 0.07); }

.white-bg {
  background-color: #ffffff; }

.black-bg {
  background-color: #1b2432; }

.blue-bg {
  background-color: #0364B1; }

.navy-bg {
  background-color: #AAD5F7; }

.transparent-bg {
  background-color: transparent !important; }

.blue-gradient-bg {
  background: #a177ff;
  background: linear-gradient(45deg, #a177ff, #02ceff);
  background-size: 120%;
  background-position: 100% 0; }

.orange-gradient-bg {
  background: #ff6d92;
  overflow: hidden;
  position: relative; }
  .orange-gradient-bg:before {
    background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…VpZ2h0PSIxMDEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==");
    background: radial-gradient(circle at center, rgba(255, 185, 0, 0.6) 10%, rgba(252, 99, 107, 0.8) 40%, rgba(255, 109, 146, 0) 70%);
    -webkit-animation: h 8s linear infinite;
    animation: h 8s linear infinite;
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    top: -50%;
    left: -50%;
    background-repeat: no-repeat; }
  .orange-gradient-bg:after {
    background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…BoZWlnaHQ9IjEwMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+");
    background: radial-gradient(circle at center, rgba(255, 185, 0, 0.5) 0, rgba(252, 99, 107, 0.4) 40%, rgba(255, 109, 146, 0) 70%);
    content: "";
    position: absolute;
    width: 300%;
    height: 300%;
    top: -100%;
    left: -100%;
    background-repeat: no-repeat;
    -webkit-animation: g 8s linear infinite;
    animation: g 8s linear infinite; }

.green-bg {
  background-color: #008e47; }

.orange-bg {
  background-color: #EA862E; }

.red-bg {
  background-color: #E9201F; }

.gray-bg {
  background-color: #49505b; }

.lightGray-bg {
  background-color: #d5d5d5; }

.offwhite-bg {
  background-color: #f5f5f5; }

.yellow-bg {
  background-color: #ffb900; }

/** END : BACKGROUND COLOR **/
/** BORDER COLOR **/
.white-border {
  border-color: #ffffff; }

.black-border {
  border-color: #1b2432; }

.blue-border {
  border-color: #0364B1; }

.navy-border {
  border-color: #AAD5F7; }

.green-border {
  border-color: #008e47; }

.orange-border {
  border-color: #EA862E; }

.red-border {
  border-color: #E9201F; }

.gray-border {
  border-color: #49505b; }

.lightGray-border {
  border-color: #d5d5d5; }

.offwhite-border {
  border-color: #f5f5f5; }

.yellow-border {
  border-color: #ffb900; }

.no-border {
  border: 0px transparent; }

.square-border {
  border-radius: 0px; }

/** END : BORDER COLOR **/
/** .BTN, .BADGE **/
.btn, .badge {
  border-radius: 0px; }

.btn-success, .badge-success {
  background-color: #008e47; }

.btn-info, .badge-info {
  background-color: #0364B1; }

.btn-warning, .badge-warning {
  background-color: #ffb900; }

.btn-danger, .badge-danger {
  background-color: #E9201F; }

.btn-info.btn-rounded {
  border-color: #0364B1;
  border-radius: 0px;
  color: #ffffff; }

.btn-success.btn-rounded {
  border-color: #008e47;
  border-radius: 0px;
  color: #ffffff; }

.btn-warning.btn-rounded {
  border-color: #ffb900;
  border-radius: 0px;
  color: #ffffff; }

.btn-danger.btn-rounded {
  border-color: #E9201F;
  border-radius: 0px;
  color: #ffffff; }

.btn-default.btn-rounded {
  border-color: #ffffff;
  border-radius: 0px;
  color: #ffffff; }

/** END : .BTN, .BADGE **/
/** Form **/
.form-control {
  border-radius: 0px; }

/** END: Form **/
/** General **/
h1 {
  color: #ffffff;
  margin-top: 10%;
  font-size: 5vw;
  line-height: 1.2; }

h2 {
  font-size: 2.5em;
  color: #1b2432; }

h3 {
  font-size: 2em;
  color: #1b2432; }

h4 {
  font-size: 1.35em;
  color: #1b2432; }

p, a {
  color: #1b2432; }

p {
  line-height: 1.5; }

.fixed {
  position: fixed; }

.hover:hover {
  cursor: pointer; }

.scroll {
  overflow: scroll; }

/** END: General **/
/** HEADER AREA **/
header {
  background-color: rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3; }
  header .pre-nav ul, header .post-nav ul {
    margin-bottom: 0; }
    header .pre-nav ul li a, header .pre-nav ul li a:hover, header .post-nav ul li a, header .post-nav ul li a:hover {
      font-size: 10px;
      color: #f5f5f5;
      text-decoration: none; }
  header .main-nav.navbar.navbar-default {
    background-color: transparent;
    margin-bottom: 0; }
    header .main-nav.navbar.navbar-default li a {
      color: #ffffff;
      transition: all 0.3s ease; }
    header .main-nav.navbar.navbar-default li a:hover {
      font-size: 13px;
      color: #ffffff; }
    header .main-nav.navbar.navbar-default .navbar-nav > .active > a, header .main-nav.navbar.navbar-default .navbar-nav > .active > a:focus, header .main-nav.navbar.navbar-default .navbar-nav > .active > a:hover, header .main-nav.navbar.navbar-default .navbar-nav > .open > a, header .main-nav.navbar.navbar-default .navbar-nav > .open > a:hover {
      color: #ffffff;
      background-color: transparent; }

/** END : Header area **/
/** DROPDOWN **/
.dropdown:hover .dropdown-menu {
  display: block; }

.dropdown-menu {
  background-color: rgba(0, 0, 0, 0.35);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  overflow: hidden;
  margin: 0; }
  .dropdown-menu li a, .dropdown-menu li a:hover {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    background-color: transparent; }
  .dropdown-menu .divider {
    background-color: rgba(0, 0, 0, 0.2);
    border-color: rgba(0, 0, 0, 0.2); }

/** END : DROPDOWN **/
/** Section-heading **/
.section-heading {
  width: 100%;
  margin: 30px 0; }
  .section-heading h1 {
    text-align: center;
    font-size: 32px;
    font-weight: 600; }
  .section-heading h2 {
    text-align: center;
    font-size: 28px;
    font-weight: 400; }
  .section-heading .underline {
    background-color: #0364B1;
    width: 70%;
    padding: 0.5px;
    display: block;
    margin-left: 15%; }
  .section-heading p {
    color: #1b2432;
    text-align: center;
    margin-top: 15px;
    opacity: 0.6; }

/** END : Section-heading **/
/** Page Banner **/
.page-banner {
  position: relative; }
  .page-banner img {
    width: 100%;
    position: relative; }
  .page-banner .cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0; }

/** END : Page Banner **/
/** Home > Slider on top **/
.slider {
  position: relative; }
  .slider .background {
    height: 100%;
    width: 100%; }
    .slider .background img {
      width: 100%;
      height: 100%; }
  .slider .foreground {
    padding: 20% 0 10% 0;
    position: relative;
    background-color: transparent; }
    .slider .foreground h1 {
      font-size: 57px;
      font-weight: 700;
      opacity: 0.8; }
    .slider .foreground a {
      width: 200px; }

.slider > .foreground {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

/** END : Home > Slider on top **/
/** Footer area **/
footer img.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

footer .row {
  background-color: rgba(0, 0, 0, 0.5);
  position: relative; }

footer .social {
  margin: 30px 0; }
  footer .social a {
    background-color: #ffffff;
    color: #1b2432;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 10px 10px;
    margin-right: 10px; }
    footer .social a i {
      font-size: 18px !important; }

footer .contact {
  margin: 30px 0; }
  footer .contact span {
    margin-right: 0;
    margin-left: 10px; }
    footer .contact span address {
      display: inline-flex; }
  footer .contact i {
    font-size: 22px;
    width: 30px;
    margin: 5px 0; }

footer address i {
  font-size: 28px !important; }

footer address span {
  margin-left: 7px; }

footer h3 {
  font-size: 26px;
  font-weight: 100;
  margin: 30px 0;
  color: #ffffff; }

footer .copyright {
  background-color: rgba(0, 0, 0, 0.9);
  padding: 15px 0 5px; }

/** END : Footer area **/
/** Login Page **/
.login-page {
  padding: 30px 0; }

.social-login .or {
  background-color: #d5d5d5;
  height: 1px; }

.social-login i {
  margin-top: -20px;
  position: absolute;
  background-color: #ffffff;
  padding: 10px;
  color: #1b2432;
  font-weight: 600; }

.login-area .input-group {
  height: 40px;
  border: 1px solid #d5d5d5; }

.login-area .form-control {
  height: 40px;
  background-color: #f5f5f5;
  border: 0px transparent;
  color: #1b2432; }

.login-area .input-group-addon {
  line-height: 38px;
  color: rgba(0, 0, 0, 0.5);
  background-color: #f5f5f5;
  border: 0px transparent;
  font-size: 22px; }

.login-area .login-button {
  width: 150px; }

.login-area .signup-button {
  border-top: 1px solid #d5d5d5; }

/** END : Login Page **/
/** Signup page **/
.signup-page {
  padding: 100px 0; }
  .signup-page h1 {
    color: #1b2432;
    font-weight: 700;
    font-size: 26px; }

/** END : Signup page **/
* {
  font-family: 'Kalpurush', 'Open Sans', sans-serif; }

body {
  background-color: #ffffff; }
  body.white-bg {
    background-color: #ffffff; }

p {
  text-align: justify; }

p, a {
  font-size: 11px; }

h2, h3 {
  font-size: 12px; }

main.main {
  width: auto;
  display: block;
  overflow: hidden;
  margin: 0 15px; }

.adgroup1 .ad a {
  width: calc(100% - 5px);
  min-height: 90px;
  display: block;
  -webkit-background-size: cover;
  background-size: cover; }
  .adgroup1 .ad a img {
    max-height: 100%; }

.adgroup1 .ad:first-child a {
  margin-right: 5px; }

.adgroup1 .ad:last-child a {
  margin-left: 5px; }

.logo-area {
  background-color: #F6F8EA; }
  .logo-area .logo {
    height: 100%;
    max-width: 100%; }
  .logo-area .logo-right {
    background: transparent url("/public/img/theme/logo-right.png") no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position-x: right;
    padding: 5px; }
    .logo-area .logo-right a {
      font-size: 11px;
      font-weight: 600; }
    .logo-area .logo-right .btn {
      width: 130px; }
  .logo-area .google-custom-search {
    max-width: 300px; }
    .logo-area .google-custom-search .gsc-input-box {
      background: transparent url("/public/img/theme/custom-search-placeholder.png") no-repeat;
      -webkit-background-size: cover;
      background-size: cover;
      border: 0px transparent; }
      .logo-area .google-custom-search .gsc-input-box input.gsc-input {
        background: transparent none !important;
        padding: 10px 20px !important;
        color: white;
        font-size: 12px; }
    .logo-area .google-custom-search .gsc-control-cse {
      background-color: transparent;
      padding: 0px;
      border: 0px transparent; }
    .logo-area .google-custom-search .gsc-search-button {
      position: relative; }
      .logo-area .google-custom-search .gsc-search-button input {
        background: transparent url("/public/img/theme/search-hand.png") no-repeat center;
        -webkit-background-size: contain;
        background-size: contain;
        border: 0px transparent;
        height: 30px;
        width: 30px;
        display: block;
        padding: 0px 30px 0 0; }
    .logo-area .google-custom-search .gsc-input {
      padding: 0px; }

.nav-dates {
  padding: 5px;
  background-color: #FDE4DF; }
  .nav-dates p {
    font-size: 11px;
    font-weight: 600;
    padding-bottom: 0px;
    padding-top: 5px;
    margin-bottom: 0px; }
  .nav-dates .btn {
    width: 130px;
    margin-left: 20px;
    font-weight: 600; }

.memory {
  background: transparent url("/public/img/theme/memory.jpg") no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: 0 center; }
  .memory .type1 {
    width: 96%;
    margin: 3px 2%; }
    .memory .type1 p {
      background-color: rgba(255, 255, 255, 0.7);
      padding: 5px 5px 1px 5px;
      display: inline-block;
      margin-bottom: 0px; }
      .memory .type1 p img {
        margin-left: -5px;
        margin-top: -5px;
        max-height: 40px; }
    .memory .type1 figcaption {
      background-color: #CBC9CE; }
      .memory .type1 figcaption h2 {
        font-size: 11px;
        margin: 5px;
        margin-right: 10px;
        padding: 5px 5px 2px; }
  .memory .udjapon {
    position: relative; }
    .memory .udjapon:before {
      content: " ";
      position: absolute;
      left: -10px;
      top: 0;
      height: 100%;
      width: 10px;
      background: transparent url("/public/img/theme/border-color.png") no-repeat center center;
      -webkit-background-size: cover;
      background-size: cover; }
    .memory .udjapon article {
      max-height: 200px;
      overflow-y: scroll; }
      .memory .udjapon article figure p {
        padding: 7px 7px 0;
        display: flex; }

.krorpotro {
  background: transparent url("/public/img/theme/krorpotro.png") no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: 0 center; }
  .krorpotro .aro-jante {
    padding-left: 20px; }
    .krorpotro .aro-jante:before {
      width: 18px; }
    .krorpotro .aro-jante li:before {
      color: #E9201F; }
    .krorpotro .aro-jante li a {
      color: #008e47;
      font-size: 10px; }
    .krorpotro .aro-jante li span {
      color: #1b2432;
      font-size: 10px;
      line-height: 1.7; }
  .krorpotro .type1 {
    width: 96%;
    margin: 3px 2%; }
    .krorpotro .type1 p {
      background-color: rgba(255, 255, 255, 0.7);
      padding: 5px 5px 1px 5px;
      display: inline-block;
      margin-bottom: 0px; }
      .krorpotro .type1 p img {
        margin-left: -5px;
        margin-top: -5px;
        max-height: 40px; }
    .krorpotro .type1 figcaption {
      background-color: #CBC9CE; }
      .krorpotro .type1 figcaption h2 {
        font-size: 11px;
        margin: 5px;
        margin-right: 10px;
        padding: 5px 5px 2px; }
  .krorpotro .udjapon {
    position: relative;
    margin: 20px 0px 0px 0px;
    padding-top: 20px; }
    .krorpotro .udjapon:before {
      content: " ";
      position: absolute;
      left: -10px;
      top: 0;
      height: 100%;
      width: 10px;
      background: transparent url("/public/img/theme/border-color.png") no-repeat center center;
      -webkit-background-size: cover;
      background-size: cover; }
  .krorpotro article {
    max-height: 300px;
    overflow-y: scroll; }
    .krorpotro article figure.type2 {
      display: inline-block;
      margin-bottom: 7px; }
      .krorpotro article figure.type2 h3 {
        position: relative;
        padding-left: 55px;
        font-size: 12px;
        padding-top: 5px;
        padding-bottom: 5px;
        margin: 5px 0px 3px 7px;
        height: 40px;
        padding-right: 10px;
        align-items: center;
        display: flex;
        flex-wrap: wrap; }
        .krorpotro article figure.type2 h3 img:first-child {
          position: absolute;
          top: -6px;
          left: -7px;
          width: 57px;
          height: 45px; }
        .krorpotro article figure.type2 h3 img:last-child {
          position: absolute;
          bottom: -7px;
          right: 3px;
          width: 20px;
          height: 17px; }
      .krorpotro article figure.type2 .cover {
        background: transparent url("/public/img/theme/globe.png") no-repeat center center;
        -webkit-background-size: cover;
        background-size: cover;
        position: relatice; }
        .krorpotro article figure.type2 .cover img {
          margin: 5px auto;
          max-height: 90px; }
        .krorpotro article figure.type2 .cover span {
          position: absolute;
          bottom: 0px;
          background-color: rgba(0, 0, 0, 0.5);
          color: #ffffff;
          padding: 5px;
          text-align: right;
          padding-right: 30px;
          width: 100%;
          font-size: 10px; }

.live-tv {
  background: transparent url("/public/img/theme/tv.png") no-repeat center center;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  height: 290px; }
  .live-tv iframe {
    position: absolute;
    top: 2px;
    left: 30px;
    height: calc(100% - 34px);
    width: calc(100% - 60px); }
  .live-tv .labels {
    display: flex;
    position: absolute;
    z-index: 1;
    width: 100%;
    justify-content: space-between;
    padding: 5px 25px;
    align-items: flex-start; }
    .live-tv .labels a:hover {
      text-decoration: none; }
    .live-tv .labels .expand {
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 0px 0px 5px 5px;
      padding: 0 5px;
      font-size: 12px; }
    .live-tv .labels .make-live {
      background-color: #E9201F;
      border: 2px solid #1b2432;
      border-radius: 10px;
      color: #ffffff;
      padding: 0px 7px;
      font-size: 14px; }

.live-cast {
  border: 2px solid #1b2432; }
  .live-cast .old-programs article {
    max-height: 247px;
    overflow-y: scroll;
    margin-bottom: 5px; }
  .live-cast .ajker-program {
    background: transparent url("/public/img/theme/ajker-program.png") no-repeat center 0;
    -webkit-background-size: cover;
    background-size: cover;
    padding: 50px 5px 0;
    border-left: 1px solid #1b2432;
    border-right: 1px solid #1b2432; }
    .live-cast .ajker-program .contents {
      max-height: 240px;
      overflow-y: scroll; }
      .live-cast .ajker-program .contents p {
        margin-bottom: 5px; }
    .live-cast .ajker-program .advert img {
      height: 100%; }
  .live-cast aside {
    position: relative;
    height: 290px;
    overflow: hidden; }
    .live-cast aside .advert {
      max-width: 150px; }
      .live-cast aside .advert img {
        height: 100%; }
    .live-cast aside header {
      position: relative;
      background: 0 0;
      z-index: initial;
      width: calc(100% - 150px); }
      .live-cast aside header .live-icon {
        border: 1px solid #1b2432;
        background-color: #E9201F;
        padding: 3px 5px;
        border-radius: 10px;
        color: #ffffff;
        margin: 20px 10px 0 0; }
    .live-cast aside main {
      width: calc(100% - 150px); }
      .live-cast aside main h5 {
        margin-bottom: 0;
        margin-top: 3px; }
    .live-cast aside footer {
      position: absolute;
      bottom: 0;
      width: calc(100% - 150px);
      text-align: center;
      border-top: 1px solid #1b2432;
      padding: 5px; }
      .live-cast aside footer p {
        text-align: center;
        margin-bottom: 5px; }
        .live-cast aside footer p span {
          color: #6A3394;
          border-bottom: 1px solid #1b2432; }
      .live-cast aside footer img {
        max-width: 50px; }

.detail h1 {
  font-size: 18px;
  margin: 5px auto 10px;
  font-weight: 600; }

.detail .author-date {
  border-bottom: 1px solid #1b2432; }

.detail .author {
  border-right: 1px solid #008e47; }

.detail .social-links p img {
  max-height: 15px; }

.disclaimer {
  border: 1px solid #008e47;
  position: relative;
  padding: 5px 75px; }
  .disclaimer:before {
    position: absolute;
    content: " ";
    width: 70px;
    height: 100%;
    top: 0;
    left: 0;
    background: transparent url("/public/img/theme/disclaimer-border-left.png") no-repeat;
    -webkit-background-size: cover;
    background-size: cover; }
  .disclaimer:after {
    position: absolute;
    content: " ";
    width: 70px;
    height: 100%;
    top: 0;
    right: 0;
    background: transparent url("/public/img/theme/disclaimer-border-right.png") no-repeat;
    -webkit-background-size: cover;
    background-size: cover; }
  .disclaimer p {
    font-size: 12px;
    text-align: center;
    margin: 0px; }

.rating-summary {
  margin: 0 auto;
  height: 32px;
  align-items: center;
  padding: 2px 10px;
  display: flex;
  justify-content: space-between; }
  .rating-summary .btn {
    padding: 3px 5px; }

.type-comment {
  height: 150px;
  border: 2px solid #49505b;
  margin-top: 4px; }

.comment-address {
  display: flex;
  background: 0 0;
  padding: 5px 60px 5px 0px;
  justify-content: flex-end; }
  .comment-address .form-control {
    max-width: 220px;
    margin-right: 10px;
    border: 2px solid #49505b; }
  .comment-address img {
    max-width: 35px;
    margin-right: 5px; }

.user-comment-preheading {
  border-bottom: 2px solid #49505b; }

.comments .comment .likes a {
  display: block; }
  .comments .comment .likes a span {
    background-color: #d5d5d5;
    width: 70px;
    padding: 1px 5px;
    display: inline-block; }

p.strong {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.3; }

.single-slider.slick-slider .slick-next, .single-slider.slick-slider .slick-prev {
  top: 60%;
  background-color: transparent;
  background-repeat: no-repeat;
  height: 31px;
  width: 17px;
  background-size: cover;
  z-index: 1; }
  .single-slider.slick-slider .slick-next:before, .single-slider.slick-slider .slick-prev:before {
    content: "";
    opacity: 0;
    display: none; }

.single-slider.slick-slider .slick-next {
  right: -3px;
  background-image: url("/public/img/theme/right-arrow.png"); }

.single-slider.slick-slider .slick-prev {
  left: -3px;
  background-image: url("/public/img/theme/left-arrow.png"); }

.get-more {
  position: relative; }
  .get-more:before {
    content: " ";
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    height: 2px;
    width: calc(100% - 5px);
    background-color: #0364B1; }

.nav-area .navbar-default .navbar-header a {
  padding: 5px;
  height: 34px; }

.nav-area .navbar-default .navbar-header i {
  color: #ffffff; }

.nav-area .navbar-default .navbar-nav li a {
  border: 1px solid #A25922;
  border-radius: 5px;
  padding: 5px;
  margin: 2px;
  background-color: #F9B81E;
  color: #000;
  font-weight: 600; }

.news-slider {
  position: relative; }
  .news-slider .figure {
    background-color: rgba(255, 255, 255, 0.5);
    border: 1px solid #1b2432;
    padding: 10px 0 10px 10px; }
  .news-slider figure {
    background-color: #FFF0BD; }
  .news-slider .slick-next, .news-slider .slick-prev {
    top: 50%;
    width: auto;
    height: auto; }
  .news-slider .slick-next:before, .news-slider .slick-prev:before {
    width: 32px;
    font-size: 60px !important;
    display: inline-block;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #E9201F; }
  .news-slider .slick-next:before {
    content: "\f105"; }
  .news-slider .slick-prev:before {
    content: "\f104"; }

.most-read #latest-list.fade.in.active {
  display: block; }

.most-read #latest-list.fade {
  display: none; }

.most-read .latest {
  border: 2px solid #49505b; }

.most-read .make-scroll {
  margin: 0; }
  .most-read .make-scroll a {
    font-size: 22px;
    margin: 5px 0;
    line-height: 1; }

.most-read ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  padding-left: 25px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  max-height: 400px; }
  .most-read ul span, .most-read ul ul {
    display: flex;
    flex-wrap: wrap;
    flex: 1; }
  .most-read ul li.btn {
    display: flex;
    align-items: center; }
  .most-read ul li {
    margin: 0;
    padding: 0;
    list-style: none; }
    .most-read ul li a {
      height: auto;
      display: flex;
      margin: 5px; }
      .most-read ul li a img {
        max-width: 75px;
        max-height: 40px; }
      .most-read ul li a span {
        background-color: rgba(255, 255, 255, 0.5);
        padding: 5px; }

.most-read .preview {
  padding-right: 0px; }
  .most-read .preview .light-gray-border {
    border: 2px solid #d5d5d5; }
  .most-read .preview img {
    width: 100%; }
  .most-read .preview header {
    padding: 5px 10px; }
    .most-read .preview header.no-pull {
      padding: 0px; }
    .most-read .preview header h4 {
      font-size: 14px; }
    .most-read .preview header h5 {
      font-size: 12px; }
    .most-read .preview header h3 {
      font-size: 22px;
      margin-top: 0px; }
  .most-read .preview p {
    padding: 7px 10px; }
  .most-read .preview .btn img {
    width: 108px;
    height: 22px;
    margin-left: 5px; }
  .most-read .preview .sidelist {
    padding-right: 0px;
    max-height: 470px;
    overflow-y: scroll; }
    .most-read .preview .sidelist ul {
      display: block;
      max-height: 470px; }
      .most-read .preview .sidelist ul li a {
        background-color: rgba(255, 255, 255, 0.5);
        padding: 0px;
        font-weight: 600;
        margin-bottom: 5px;
        display: inline-block; }
        .most-read .preview .sidelist ul li a:hover {
          text-decoration: none; }
        .most-read .preview .sidelist ul li a img {
          width: 100%;
          max-width: 100%;
          height: auto;
          max-height: 100%; }
        .most-read .preview .sidelist ul li a p {
          padding: 0px; }

.banner-ads .ad {
  height: 100px;
  background-color: #0364B1; }
  .banner-ads .ad img {
    height: 100%;
    width: 100%; }

.most-read ul.tab-list, .most-read ul.tab-list ul {
  padding-left: 0px;
  width: auto; }

.tab-list {
  border-bottom: 2px solid #EA862E;
  overflow: hidden; }
  .tab-list li.btn {
    background-color: #EA862E;
    padding: 5px; }
    .tab-list li.btn.active a.btn {
      border-bottom: 2px solid #E9201F; }
  .tab-list a.btn {
    background-color: #D8B386;
    font-weight: 600; }
  .tab-list li.placeholder {
    background: #D8B386;
    width: 100%;
    margin: 5px; }

.tabables h5, .tabables p {
  overflow: hidden; }
  .tabables h5 a, .tabables p a {
    font-size: 14px;
    font-weight: 600;
    min-height: 50px;
    line-height: 1.5;
    padding-right: 5px;
    display: block; }
    .tabables h5 a.btn, .tabables p a.btn {
      min-height: auto;
      display: initial; }

.tabables p a {
  font-size: 12px; }

.photo-slider {
  overflow: hidden; }
  .photo-slider .slides {
    border-bottom: 5px solid #A8E1EA; }
    .photo-slider .slides .slide {
      position: relative; }
      .photo-slider .slides .slide img {
        width: 100%; }
      .photo-slider .slides .slide .caption {
        position: absolute;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        font-size: 14px;
        margin: 0;
        padding: 5px 0; }
  .photo-slider .thumbs {
    background-color: #1b2432;
    margin: 5px 30px;
    max-width: calc( 100% - 60px);
    max-height: 50px; }
    .photo-slider .thumbs .thumb {
      margin: 5px;
      padding-top: 3px; }
      .photo-slider .thumbs .thumb.slick-current {
        border: 4px solid #ffffff;
        padding: 0px;
        transition: all 0.5s fade-in-out; }
    .photo-slider .thumbs .slick-next:before, .photo-slider .thumbs .slick-prev:before {
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      color: #008e47;
      font-size: 24px; }
    .photo-slider .thumbs .slick-next:before {
      content: "\f0da"; }
    .photo-slider .thumbs .slick-prev:before {
      content: "\f0d9"; }

.photo-gallery {
  max-height: 290px;
  overflow-y: scroll;
  margin-bottom: 5px; }

footer img {
  margin: 0 auto; }

footer .holder {
  background: transparent url("/public/img/theme/footer.png") no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
  padding: 10px 0;
  display: flex; }

footer .holder > div {
  padding: 0 3px;
  flex: 1; }
  footer .holder > div:first-child {
    max-width: 235px; }
  footer .holder > div:last-child {
    min-width: 320px; }

footer .links {
  margin: 10px 0;
  display: flex;
  justify-content: space-around;
  padding: 0 10%; }

footer p {
  font-size: 11px;
  margin-bottom: 2px;
  line-height: 1; }
  footer p .btn-rounded {
    border-radius: 10px; }

.aro-jante {
  position: relative;
  display: list-item;
  padding-left: 30px; }
  .aro-jante:before {
    content: " ";
    background: transparent url("/public/img/theme/aro.png") no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 30px;
    background-position: 0 0;
    background-size: contain; }
  .aro-jante li {
    font-size: 12px; }
    .aro-jante li:before {
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 10px;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\f111";
      color: #AAD5F7; }
    .aro-jante li a:hover {
      text-decoration: none; }

.right-sidebar .more {
  text-align: center; }
  .right-sidebar .more img {
    border-radius: 13px; }

.right-sidebar .advert img {
  width: 100%; }

.right-sidebar .shompadokiyo {
  background-color: #FDF4F7; }
  .right-sidebar .shompadokiyo .label img {
    width: 100%; }
  .right-sidebar .shompadokiyo h4 {
    font-size: 14px;
    line-height: 1.3; }

.right-sidebar .interview .more {
  margin-top: -5px;
  text-align: center; }

.right-sidebar .interview header {
  position: relative;
  background: 0 0; }
  .right-sidebar .interview header img {
    margin: 0 auto; }

.right-sidebar .interview .holder {
  border-radius: 40px;
  border: 2px solid #0364B1;
  overflow: hidden; }

.right-sidebar .interview article {
  background-color: #F1F6D5;
  font-size: 12px; }

.right-sidebar .interview h3 {
  border-top: 2px solid #0364B1;
  border-bottom: 2px solid #0364B1;
  font-size: 14px;
  padding: 5px;
  margin: 5px auto; }

.right-sidebar .interview h4 {
  font-size: 10px;
  background: white;
  background: -moz-linear-gradient(left, white 0%, #a8e1ea 100%);
  background: -webkit-linear-gradient(left, white 0%, #a8e1ea 100%);
  background: linear-gradient(to right, white 0%, #a8e1ea 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#a8e1ea',GradientType=1 ); }

.right-sidebar .career p {
  display: flex; }

.right-sidebar .career h4 {
  font-size: 12px; }

.right-sidebar .career img {
  margin-top: 10%; }

.right-sidebar .opinion h4 {
  font-size: 14px;
  background: #A2D3AD url(/public/img/theme/opinion-underline.png) no-repeat;
  background-position: 0 100%;
  padding-bottom: 20px;
  background-size: contain;
  margin-top: 0px; }
  .right-sidebar .opinion h4 img {
    width: 100%; }

.right-sidebar .opinion .underline {
  color: #0364B1;
  border-bottom: 1px solid #0364B1; }

.right-sidebar .question h4 img {
  width: 100%; }

.right-sidebar .question .q {
  background-color: #FFF3E3;
  border-top: 2px solid #008e47;
  border-bottom: 2px solid #008e47; }

.right-sidebar .question .choices span {
  position: relative;
  margin-right: 10px; }
  .right-sidebar .question .choices span:hover {
    cursor: pointer; }
  .right-sidebar .question .choices span .fa {
    font-size: 12px;
    color: #1b2432; }
  .right-sidebar .question .choices span input {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0; }

.right-sidebar .question .process {
  background-color: #A42189; }
  .right-sidebar .question .process .btn-rounded {
    border-radius: 15px; }

.right-sidebar .question .result p {
  display: block;
  padding: 5px; }
  .right-sidebar .question .result p .stat {
    width: 50px;
    display: inline-block; }
  .right-sidebar .question .result p .progress-range {
    background-color: #E9201F;
    height: 7px;
    width: 10%;
    display: inline-block; }

.right-sidebar .question .result .last-result {
  margin: 0;
  padding: 0; }
  .right-sidebar .question .result .last-result img {
    width: 100%; }

.right-sidebar .news-summary h4 {
  font-size: 14px; }

.right-sidebar .old-version img {
  width: 100%; }

.right-sidebar .old-version .choices {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding: 5px 0; }
  .right-sidebar .old-version .choices span.label {
    width: 75px; }
  .right-sidebar .old-version .choices ul {
    width: calc( 100% - 75px);
    display: flex;
    flex-wrap: wrap;
    padding-left: 22px;
    justify-content: flex-end; }
    .right-sidebar .old-version .choices ul li {
      position: relative;
      margin-left: -22px;
      border-radius: 20px 0 0 0;
      padding-top: 2px;
      height: 23px;
      overflow: hidden; }
      .right-sidebar .old-version .choices ul li select {
        background: 0 0;
        border: 0px transparent; }
      .right-sidebar .old-version .choices ul li .fa {
        position: absolute;
        right: 22px;
        top: 5px; }
    .right-sidebar .old-version .choices ul .day {
      background-color: #AAD5F7; }
      .right-sidebar .old-version .choices ul .day select {
        width: 57px; }
    .right-sidebar .old-version .choices ul .month {
      background-color: #EA862E; }
      .right-sidebar .old-version .choices ul .month select {
        width: 90px; }
    .right-sidebar .old-version .choices ul .year {
      background-color: #D7E164; }
      .right-sidebar .old-version .choices ul .year select {
        width: 72px; }
    .right-sidebar .old-version .choices ul .submit {
      background-color: #008e47;
      padding: 1px 5px; }
      .right-sidebar .old-version .choices ul .submit button {
        background: 0 0;
        color: #ffffff;
        border: 0px transparent; }

.right-sidebar .quize .date {
  background: transparent url(/public/img/theme/quize-heading.png) no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  height: 40px; }
  .right-sidebar .quize .date span {
    background: #0bb0a2;
    background: -moz-linear-gradient(left, #0bb0a2 0%, white 34%, white 71%, #0bb0a2 100%);
    background: -webkit-linear-gradient(left, #0bb0a2 0%, white 34%, white 71%, #0bb0a2 100%);
    background: linear-gradient(to right, #0bb0a2 0%, white 34%, white 71%, #0bb0a2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0bb0a2', endColorstr='#0bb0a2',GradientType=1 );
    padding: 3px 5px;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 10px; }

.right-sidebar .quize h4 {
  background: transparent url("/public/img/theme/quize-heading-bg2.png") no-repeat;
  background-size: 100% 45px;
  background-position: center center; }
  .right-sidebar .quize h4 a {
    font-size: 14px;
    font-weight: 600; }
    .right-sidebar .quize h4 a.btn {
      border-radius: 15px;
      padding: 2px 7px;
      font-size: 12px;
      margin: 2px 7px 2px 0;
      border: 1px solid #0364B1; }

.right-sidebar .quize .questions .question {
  font-size: 12px; }
  .right-sidebar .quize .questions .question .q {
    border: 0px transparent;
    background-color: transparent;
    margin-bottom: 0px; }

.right-sidebar .quize .contact {
  background-color: #D1E9DB; }
  .right-sidebar .quize .contact .col-xs-6 {
    display: inline-flex; }
  .right-sidebar .quize .contact label {
    font-size: 10px;
    font-weight: 400;
    color: #008e47; }
  .right-sidebar .quize .contact .form-control {
    font-size: 12px;
    border-radius: 0px;
    border: 1px solid #1b2432;
    background: 0 0;
    height: 18px; }

.right-sidebar .stockexchange {
  background-color: rgba(255, 255, 255, 0.5); }
  .right-sidebar .stockexchange h4 {
    background-color: #8C3323;
    color: #ffffff;
    text-align: center;
    padding: 5px 0px;
    margin: 0; }
  .right-sidebar .stockexchange ul {
    display: list-item;
    padding: 5px 0; }
    .right-sidebar .stockexchange ul li {
      list-style-type: disc;
      list-style-position: inside; }

.right-sidebar .money-exchange h4 {
  background-color: #91278B;
  color: #ffffff;
  text-align: center;
  padding: 5px 0px;
  margin: 0; }

.right-sidebar .money-exchange table thead {
  background-color: #FEE9EE; }

.right-sidebar .money-exchange table td, .right-sidebar .money-exchange table th {
  border: 1px solid #1b2432;
  text-align: center;
  font-size: 12px; }

.right-sidebar .prayer {
  border: 2px solid #008e47;
  border-radius: 5px;
  font-size: 11px; }
  .right-sidebar .prayer img {
    width: 100%; }
  .right-sidebar .prayer h4 {
    font-size: 12px;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 5px;
    border-top: 2px solid #008e47;
    border-bottom: 2px solid #008e47; }
    .right-sidebar .prayer h4 .btn:last-child {
      font-size: 10px;
      padding-top: 7px;
      text-align: center; }
  .right-sidebar .prayer .prayer-time {
    background-color: #F1F7DD; }
    .right-sidebar .prayer .prayer-time.table tr td {
      border: 1px solid #1b2432;
      padding: 3px;
      font-size: 10px; }
  .right-sidebar .prayer .sun .btn {
    font-size: 10px;
    padding: 5px 2px; }
    .right-sidebar .prayer .sun .btn:first-child {
      border-right: 1px solid #1b2432; }
  .right-sidebar .prayer .sehri.table tr td {
    border: 1px solid #1b2432;
    padding: 3px;
    font-size: 10px; }
  .right-sidebar .prayer .sehri.table tr:first-child {
    border-radius: 5px 0 0 0; }
  .right-sidebar .prayer .sehri.table tr:last-child {
    border-radius: 0 5px 0 0; }

.right-sidebar .shok {
  display: flex; }
  .right-sidebar .shok img {
    width: 100%;
    height: 100%; }
  .right-sidebar .shok .detail {
    border-top: 13px solid #A46504;
    border-bottom: 13px solid #A46504; }
    .right-sidebar .shok .detail h6 {
      padding: 0px 10px;
      font-size: 20px;
      line-height: 1.5; }

.right-sidebar .rashi {
  border: 1px solid #008e47; }
  .right-sidebar .rashi img {
    width: 100%; }
  .right-sidebar .rashi .holder {
    border: 1px solid #008e47;
    border-radius: 5px;
    margin: 0 0 0 5px; }
    .right-sidebar .rashi .holder h3 {
      font-size: 20px;
      padding: 0 10px;
      margin-top: 7px; }
  .right-sidebar .rashi .btn {
    font-size: 9px;
    padding: 3px 0px; }

.pagination {
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center; }
  .pagination li a {
    background-color: #E9201F;
    color: #ffffff;
    border: 0px transparent;
    margin: 20px 2px 10px !important;
    padding: 5px 10px;
    font-size: 20px; }
  .pagination li.active a {
    background-color: #EA862E; }
  .pagination li:first-child a, .pagination li:last-child a {
    background-color: transparent;
    color: #1b2432;
    font-size: 24px;
    line-height: 1; }
    .pagination li:first-child a i, .pagination li:last-child a i {
      font-size: 26px;
      margin: 0px; }

.breadcrumb {
  background-color: #AAD5F7;
  padding: 0 0 0 5px;
  overflow: hidden; }
  .breadcrumb li {
    padding: 0px;
    margin: 0px;
    position: relative; }
    .breadcrumb li:before {
      content: "";
      opacity: 0;
      display: none; }
    .breadcrumb li:after {
      border-style: solid;
      border-width: 19px 0 19px 15px;
      content: " ";
      position: absolute;
      left: 100%;
      top: 0;
      z-index: 1;
      border-color: transparent transparent transparent #ffffff; }
    .breadcrumb li:last-child a:after {
      left: 100%; }
    .breadcrumb li:first-child {
      margin-left: 0px; }
      .breadcrumb li:first-child a {
        border-radius: 0px; }
    .breadcrumb li a {
      color: #ffffff;
      position: relative;
      height: 38px;
      display: inline-flex;
      font-size: 14px;
      padding: 0px 5px;
      margin: 0px;
      margin-left: -5px;
      padding-left: 15px;
      align-items: center;
      justify-content: flex-end; }
      .breadcrumb li a:hover {
        text-decoration: none; }
      .breadcrumb li a:after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 19px 0 19px 15px;
        content: " ";
        position: absolute;
        left: calc(100% - 2px);
        top: 0;
        z-index: 2; }
      .breadcrumb li a.blue {
        background-color: #0364B1; }
        .breadcrumb li a.blue:after {
          border-color: transparent transparent transparent #0364B1; }
      .breadcrumb li a.red {
        background-color: #E9201F; }
        .breadcrumb li a.red:after {
          border-color: transparent transparent transparent #E9201F; }
      .breadcrumb li a.pink {
        background-color: #F0008B; }
        .breadcrumb li a.pink:after {
          border-color: transparent transparent transparent #F0008B; }
      .breadcrumb li a.green {
        background-color: #008e47; }
        .breadcrumb li a.green:after {
          border-color: transparent transparent transparent #008e47; }

.latest.top10 li a img {
  max-height: 45px; }

.latest.top10 li span {
  width: 100%;
  font-size: 14px;
  background-color: transparent;
  border-bottom: 2px solid #49505b; }

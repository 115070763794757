$white     : #ffffff;
$black     : #1b2432;
$blue      : #0364B1;
$navy      : #AAD5F7;
$green     : #008e47;
$lightGreen: #A1CF86;
$orange    : #EA862E;
$lightorange    : #D8B386;
$red       : #E9201F;
$darkRed   : #8C2E24;
$violate   : #6A3394;
$gray      : #49505b;
$lightGray : #d5d5d5;
$offwhite  : #f5f5f5;
$yellow    : #ffb900;
$pink      : #F0008B;

@mixin blue-gradient-bg() {
    background: #a177ff;
    background: linear-gradient(45deg,#a177ff,#02ceff);
    background-size: 120%;
    background-position: 100% 0; }

.dark-red-text {
    color: $darkRed; }

.violate-text {
    color: $violate; }

.dark-gray-bg {
    background-color: $gray; }

.light-gray-bg {
    background-color: $lightGray; }

.pink-bg {
    background-color: $pink; }

.light-orange-bg {
    background-color: $lightorange; }

.creamy-bg {
    background-color: #EAECE9; }

.overflow-hidden {
    overflow: hidden; }

.gray-border {
    border: 1px solid $gray; }

.green-overline {
    border-top: 1px solid $green; }

.light-green-bg {
    background-color: $lightGreen; }

/** PULL **/

.no-pull-top {
    padding-top: 0px; }

.no-pull-bottom {
    padding-bottom: 0px; }

.no-pull-left {
    padding-left: 0px; }

.no-pull-right {
    padding-right: 0px; }

.pull-up-10 {
    padding-top: 10px; }

.pull-down-10 {
    padding-bottom: 10px; }

.pull-left-10 {
    padding-left: 10px; }

.pull-right-10 {
    padding-right: 10px; }

.pull-up-5 {
    padding-top: 5px; }

.pull-down-5 {
    padding-bottom: 5px; }

.pull-left-5 {
    padding-left: 5px; }

.pull-right-5 {
    padding-right: 5px; }

.pull-up-15 {
    padding-top: 15px; }

.pull-down-15 {
    padding-bottom: 15px; }

.pull-left-15 {
    padding-left: 15px; }

.pull-right-15 {
    padding-right: 15px; }

.pull-up-20 {
    padding-top: 20px; }

.pull-down-20 {
    padding-bottom: 20px; }

.pull-left-20 {
    padding-left: 20px; }

.pull-right-20 {
    padding-right: 20px; }

.pull-up-50 {
    padding-top: 50px; }

.pull-down-50 {
    padding-bottom: 50px; }

.pull-left-50 {
    padding-left: 50px !important; }

.pull-right-50 {
    padding-right: 50px; }

.pull-5 {
    padding: 5px; }

.pull-10 {
    padding: 10px; }

.pull-20 {
    padding: 20px; }

.no-pull {
    padding: 0px; }

/** END: PULL **/


/** PUSH **/

.no-push {
    margin: 0px; }

.push-down--5 {
    margin-bottom: -5px !important; }

.push-5 {
    margin: 5px; }

.push-10 {
    margin: 10px; }

.push-20 {
    margin: 20px; }

.push-up-10 {
    margin-top: 10px; }

.push-down-10 {
    margin-bottom: 10px; }

.push-left-10 {
    margin-left: 10px; }

.push-right-10 {
    margin-right: 10px; }

.push-up-80 {
    margin-top: 80px; }

.push-up-5 {
    margin-top: 5px; }

.push-down-5 {
    margin-bottom: 5px; }

.push-left-5 {
    margin-left: 5px; }

.push-right-5 {
    margin-right: 5px; }

.push-up-15 {
    margin-top: 15px; }

.push-down-15 {
    margin-bottom: 15px; }

.push-left-15 {
    margin-left: 15px; }

.push-right-15 {
    margin-right: 15px; }

.push-up-20 {
    margin-top: 20px; }

.push-down-20 {
    margin-bottom: 20px; }

.push-left-20 {
    margin-left: 20px; }

.push-right-20 {
    margin-right: 20px; }

.push-up-50 {
    margin-top: 50px; }

.push-down-50 {
    margin-bottom: 50px; }

.push-left-50 {
    margin-left: 50px; }

.push-right-50 {
    margin-right: 50px; }

.push-up-100 {
    margin-top: 100px; }

/** END: PUSH **/

/** HEIGHT **/

.height-50 {
    height: 50px; }

.height-100 {
    height: 100px; }

.height-150 {
    height: 150px; }

.height-200 {
    height: 200px; }

.height-300 {
    height: 300px; }

.height-400 {
    height: 400px; }

.height-500 {
    height: 500px; }

.height-600 {
    height: 600px; }

.min-height-50 {
    min-height: 50px; }

.min-height-100 {
    min-height: 100px; }

.min-height-150 {
    min-height: 150px; }

.min-height-200 {
    min-height: 200px; }

.min-height-300 {
    min-height: 300px; }

.min-height-400 {
    min-height: 400px; }

.min-height-500 {
    min-height: 500px; }

.min-height-600 {
    min-height: 600px; }

/** END : HEIGHT**/

/** TEXT COLOR **/


.white-text {
    color: $white; }

.black-text {
    color: $black; }

.blue-text {
    color: $blue; }

.navy-text {
    color: $navy; }

.green-text {
    color: $green; }

.orange-text {
    color: $orange; }

.red-text {
    color: $red; }

.gray-text {
    color: $gray; }

.lightGray-text {
    color: $lightGray; }

.offwhite-text {
    color: $offwhite; }

.yellow-text {
    color: $yellow; }

/** END: TEXT COLOR **/


/** BACKGROUND COLOR **/

.whitish-bg {
    background-color: rgba(255, 255, 255, 0.5); }

body.white-bg .whitish-bg {
    background-color: rgba(3, 100, 177, 0.07); }

.white-bg {
    background-color: $white; }

.black-bg {
    background-color: $black; }

.blue-bg {
    background-color: $blue; }

.navy-bg {
    background-color: $navy; }

.transparent-bg {
    background-color: transparent !important; }

.blue-gradient-bg {
    @include blue-gradient-bg; }

.orange-gradient-bg {
    background: #ff6d92;
    overflow: hidden;
    position: relative;

    &:before {
        background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…VpZ2h0PSIxMDEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==');
        background: radial-gradient(circle at center,rgba(255,185,0,.6) 10%,rgba(252,99,107,.8) 40%,rgba(255,109,146,0) 70%);
        -webkit-animation: h 8s linear infinite;
        animation: h 8s linear infinite;
        content: "";
        position: absolute;
        width: 200%;
        height: 200%;
        top: -50%;
        left: -50%;
        background-repeat: no-repeat; }

    &:after {
        background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…BoZWlnaHQ9IjEwMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+');
        background: radial-gradient(circle at center,rgba(255,185,0,.5) 0,rgba(252,99,107,.4) 40%,rgba(255,109,146,0) 70%);
        content: "";
        position: absolute;
        width: 300%;
        height: 300%;
        top: -100%;
        left: -100%;
        background-repeat: no-repeat;
        -webkit-animation: g 8s linear infinite;
        animation: g 8s linear infinite; } }

.green-bg {
    background-color: $green; }

.orange-bg {
    background-color: $orange; }

.red-bg {
    background-color: $red; }

.gray-bg {
    background-color: $gray; }

.lightGray-bg {
    background-color: $lightGray; }

.offwhite-bg {
    background-color: $offwhite; }

.yellow-bg {
    background-color: $yellow; }

/** END : BACKGROUND COLOR **/

/** BORDER COLOR **/
.white-border {
    border-color: $white; }

.black-border {
    border-color: $black; }

.blue-border {
    border-color: $blue; }

.navy-border {
    border-color: $navy; }

.green-border {
    border-color: $green; }

.orange-border {
    border-color: $orange; }

.red-border {
    border-color: $red; }

.gray-border {
    border-color: $gray; }

.lightGray-border {
    border-color: $lightGray; }

.offwhite-border {
    border-color: $offwhite; }

.yellow-border {
    border-color: $yellow; }

.no-border {
    border: 0px transparent; }

.square-border {
    border-radius: 0px; }

/** END : BORDER COLOR **/

/** .BTN, .BADGE **/

.btn, .badge {
    border-radius: 0px; }

.btn-success, .badge-success {
    background-color: $green; }

.btn-info, .badge-info {
    background-color: $blue; }

.btn-warning, .badge-warning {
    background-color: $yellow; }

.btn-danger, .badge-danger {
    background-color: $red; }

.btn-info.btn-rounded {
    border-color: $blue;
    border-radius: 0px;
    color: $white; }

.btn-success.btn-rounded {
    border-color: $green;
    border-radius: 0px;
    color: $white; }

.btn-warning.btn-rounded {
    border-color: $yellow;
    border-radius: 0px;
    color: $white; }

.btn-danger.btn-rounded {
    border-color: $red;
    border-radius: 0px;
    color: $white; }

.btn-default.btn-rounded {
    border-color: $white;
    border-radius: 0px;
    color: $white; }

/** END : .BTN, .BADGE **/

/** Form **/

.form-control {
    border-radius: 0px; }

/** END: Form **/

/** General **/

h1 {
    color: $white;
    margin-top: 10%;
    font-size: 5vw;
    line-height: 1.2; }

h2 {
    font-size: 2.5em;
    color: $black; }

h3 {
    font-size: 2em;
    color: $black; }

h4 {
    font-size: 1.35em;
    color: $black; }

p, a {
    color: $black; }

p {
    line-height: 1.5; }

.fixed {
    position: fixed; }

.hover:hover {
    cursor: pointer; }

.scroll {
    overflow: scroll; }

/** END: General **/



/** HEADER AREA **/

header {
    background-color: rgba(0, 0, 0, 0.15);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;

    .pre-nav, .post-nav {

        ul {
            margin-bottom: 0;

            li {

                a, a:hover {
                    font-size: 10px;
                    color: $offwhite;
                    text-decoration: none; } } } }


    .main-nav.navbar.navbar-default {
        background-color: transparent;
        margin-bottom: 0;

        li {

            a {
                color: $white;
                transition: all 0.3s ease; }

            a:hover {
                font-size: 13px;
                color: $white; } }

        .navbar-nav>.active>a, .navbar-nav>.active>a:focus, .navbar-nav>.active>a:hover, .navbar-nav>.open>a, .navbar-nav>.open>a:hover {
            color: $white;
            background-color: transparent; } } }

/** END : Header area **/


/** DROPDOWN **/

.dropdown:hover .dropdown-menu {
    display: block; }

.dropdown-menu {
    background-color: rgba(0, 0, 0, 0.35);
    border: 1px solid rgba(0,0,0, 0.15);
    border-radius: 0;
    overflow: hidden;
    margin: 0;

    li {

        a, a:hover {
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            background-color: transparent; } }

    .divider {
        background-color: rgba(0, 0, 0, 0.2);
        border-color: rgba(0, 0, 0, 0.2); } }

/** END : DROPDOWN **/



/** Section-heading **/

.section-heading {
    width: 100%;
    margin: 30px 0;

    h1 {
        text-align: center;
        font-size: 32px;
        font-weight: 600; }

    h2 {
        text-align: center;
        font-size: 28px;
        font-weight: 400; }

    .underline {
        background-color: $blue;
        width: 70%;
        padding: 0.5px;
        display: block;
        margin-left: 15%; }

    p {
        color: $black;
        text-align: center;
        margin-top: 15px;
        opacity: 0.6; } }

/** END : Section-heading **/



/** Page Banner **/

.page-banner {
    position: relative;

    img {
        width: 100%;
        position: relative; }

    .cover {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        top: 0;
        left: 0; } }

/** END : Page Banner **/


/** Home > Slider on top **/

.slider {
    position: relative;

    .background {
        height: 100%;
        width: 100%;

        img {
            width: 100%;
            height: 100%; } }

    .foreground {
        padding: 20% 0 10% 0;
        position: relative;
        background-color: transparent;

        h1 {
            font-size: 57px;
            font-weight: 700;
            opacity: 0.8; }

        a {
            width: 200px; } } }

.slider > .foreground {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }


/** END : Home > Slider on top **/


/** Footer area **/

footer {

    img.background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }

    .row {
        background-color: rgba(0, 0, 0, 0.5);
        position: relative; }

    .social {
        margin: 30px 0;

        a {
            background-color: $white;
            color: $black;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            padding: 10px 10px;
            margin-right: 10px;

            i {
                font-size: 18px !important; } } }

    .contact {
        margin: 30px 0;

        span {
            margin-right: 0;
            margin-left: 10px;

            address {
                display: inline-flex; } }

        i {
            font-size: 22px;
            width: 30px;
            margin: 5px 0; } }

    address {

        i {
            font-size: 28px !important; }

        span {
            margin-left: 7px; } }

    h3 {
        font-size: 26px;
        font-weight: 100;
        margin: 30px 0;
        color: $white; }

    .copyright {
        background-color: rgba(0,0,0, 0.9);
        padding: 15px 0 5px; } }

/** END : Footer area **/



/** Login Page **/

.login-page {
    padding: 30px 0; }

.social-login {

    .or {
        background-color: $lightGray;
        height: 1px; }

    i {
        margin-top: -20px;
        position: absolute;
        background-color: $white;
        padding: 10px;
        color: $black;
        font-weight: 600; } }

.login-area {

    .input-group {
        height: 40px;
        border: 1px solid $lightGray; }

    .form-control {
        height: 40px;
        background-color: #f5f5f5;
        border: 0px transparent;
        color: $black; }

    .input-group-addon {
        line-height: 38px;
        color: rgba(0,0,0, 0.5);
        background-color: #f5f5f5;
        border: 0px transparent;
        font-size: 22px; }

    .login-button {
        width: 150px; }

    .signup-button {
        border-top: 1px solid $lightGray; } }


/** END : Login Page **/


/** Signup page **/

.signup-page {
    padding: 100px 0;

    h1 {
        color: $black;
        font-weight: 700;
        font-size: 26px; } }

/** END : Signup page **/

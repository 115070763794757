@import "base.sass";

@import url('//fonts.maateen.me/kalpurush/font.css');

* {
    font-family: 'Kalpurush', 'Open Sans', sans-serif; }

body {
    background-color: $white;

    &.white-bg {
        background-color: $white; } }

p {
    text-align: justify; }

p, a {
    font-size: 11px; }

h2, h3 {
    font-size: 12px; }

main.main {
    width: auto;
    display: block;
    overflow: hidden;
    margin: 0 15px; }

.adgroup1 {

    .ad {

        a {
            width: calc(100% - 5px);
            min-height: 90px;
            display: block;
            -webkit-background-size: cover;
            background-size: cover;

            img {
                max-height: 100%; } }

        &:first-child {

            a {
                margin-right: 5px; } }

        &:last-child {

            a {
                margin-left: 5px; } } } }

.logo-area {
    background-color: #F6F8EA;

    .logo {
        height: 100%;
        max-width: 100%; }

    .logo-right {
        background: transparent url('/public/img/theme/logo-right.png') no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
        background-position-x: right;
        padding: 5px;

        a {
            font-size: 11px;
            font-weight: 600; }

        .btn {
            width: 130px; } }

    .google-custom-search {
        max-width: 300px;

        .gsc-input-box {
            background: transparent url('/public/img/theme/custom-search-placeholder.png') no-repeat;
            -webkit-background-size: cover;
            background-size: cover;
            border: 0px transparent;

            input.gsc-input {
                background: transparent none !important;
                padding: 10px 20px !important;
                color: white;
                font-size: 12px; } }

        .gsc-control-cse {
            background-color: transparent;
            padding: 0px;
            border: 0px transparent; }

        .gsc-search-button {
            position: relative;

            input {
                background: transparent url('/public/img/theme/search-hand.png') no-repeat center;
                -webkit-background-size: contain;
                background-size: contain;
                border: 0px transparent;
                height: 30px;
                width: 30px;
                display: block;
                padding: 0px 30px 0 0; } }

        .gsc-input {
            padding: 0px; } } }

.nav-dates {
    padding: 5px;
    background-color: #FDE4DF;

    p {
        font-size: 11px;
        font-weight: 600;
        padding-bottom: 0px;
        padding-top: 5px;
        margin-bottom: 0px; }

    .btn {
        width: 130px;
        margin-left: 20px;
        font-weight: 600; } }

.memory {
    background: transparent url('/public/img/theme/memory.jpg') no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: 0 center;

    .type1 {
        width: 96%;
        margin: 3px 2%;

        p {
            background-color: rgba(255,255,255,0.7);
            padding: 5px 5px 1px 5px;
            display: inline-block;
            margin-bottom: 0px;

            img {
                margin-left: -5px;
                margin-top: -5px;
                max-height: 40px; } }

        figcaption {
            background-color: #CBC9CE;

            h2 {
                font-size: 11px;
                margin: 5px;
                margin-right: 10px;
                padding: 5px 5px 2px; } } }

    .udjapon {
        position: relative;

        &:before {
            content: " ";
            position: absolute;
            left: -10px;
            top: 0;
            height: 100%;
            width: 10px;
            background: transparent url('/public/img/theme/border-color.png') no-repeat center center;
            -webkit-background-size: cover;
            background-size: cover; }

        article {
            max-height: 200px;
            overflow-y: scroll;

            figure {

                p {
                    padding: 7px 7px 0;
                    display: flex; } } } } }

.krorpotro {
    background: transparent url('/public/img/theme/krorpotro.png') no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: 0 center;

    .aro-jante {
        padding-left: 20px;

        &:before {
            width: 18px; }

        li {

            &:before {
                color: $red; }

            a {
                color: $green;
                font-size: 10px; }

            span {
                color: $black;
                font-size: 10px;
                line-height: 1.7; } } }

    .type1 {
        width: 96%;
        margin: 3px 2%;

        p {
            background-color: rgba(255,255,255,0.7);
            padding: 5px 5px 1px 5px;
            display: inline-block;
            margin-bottom: 0px;

            img {
                margin-left: -5px;
                margin-top: -5px;
                max-height: 40px; } }

        figcaption {
            background-color: #CBC9CE;

            h2 {
                font-size: 11px;
                margin: 5px;
                margin-right: 10px;
                padding: 5px 5px 2px; } } }

    .udjapon {
        position: relative;
        margin: 20px 0px 0px 0px;
        padding-top: 20px;

        &:before {
            content: " ";
            position: absolute;
            left: -10px;
            top: 0;
            height: 100%;
            width: 10px;
            background: transparent url('/public/img/theme/border-color.png') no-repeat center center;
            -webkit-background-size: cover;
            background-size: cover; } }

    article {
        max-height: 300px;
        overflow-y: scroll;

        figure.type2 {
            display: inline-block;
            margin-bottom: 7px;

            h3 {
                position: relative;
                padding-left: 55px;
                font-size: 12px;
                padding-top: 5px;
                padding-bottom: 5px;
                margin: 5px 0px 3px 7px;
                height: 40px;
                padding-right: 10px;
                align-items: center;
                display: flex;
                flex-wrap: wrap;

                img:first-child {
                    position: absolute;
                    top: -6px;
                    left: -7px;
                    width: 57px;
                    height: 45px; }

                img:last-child {
                    position: absolute;
                    bottom: -7px;
                    right: 3px;
                    width: 20px;
                    height: 17px; } }

            .cover {
                background: transparent url('/public/img/theme/globe.png') no-repeat center center;
                -webkit-background-size: cover;
                background-size: cover;
                position: relatice;

                img {
                    margin: 5px auto;
                    max-height: 90px; }

                span {
                    position: absolute;
                    bottom: 0px;
                    background-color: rgba(0,0,0, 0.5);
                    color: $white;
                    padding: 5px;
                    text-align: right;
                    padding-right: 30px;
                    width: 100%;
                    font-size: 10px; } } } } }

.live-tv {
    background: transparent url('/public/img/theme/tv.png') no-repeat center center;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    height: 290px;

    iframe {
        position: absolute;
        top: 2px;
        left: 30px;
        height: calc(100% - 34px);
        width: calc(100% - 60px); }

    .labels {
        display: flex;
        position: absolute;
        z-index: 1;
        width: 100%;
        justify-content: space-between;
        padding: 5px 25px;
        align-items: flex-start;

        a:hover {
            text-decoration: none; }

        .expand {
            background-color: rgba(255, 255, 255, 0.8);
            border-radius: 0px 0px 5px 5px;
            padding: 0 5px;
            font-size: 12px; }

        .make-live {
            background-color: $red;
            border: 2px solid $black;
            border-radius: 10px;
            color: $white;
            padding: 0px 7px;
            font-size: 14px; } } }

.live-cast {
    border: 2px solid $black;

    .old-programs {

        article {
            max-height: 247px;
            overflow-y: scroll;
            margin-bottom: 5px; } }

    .ajker-program {
        background: transparent url('/public/img/theme/ajker-program.png') no-repeat center 0;
        -webkit-background-size: cover;
        background-size: cover;
        padding: 50px 5px 0;
        border-left: 1px solid $black;
        border-right: 1px solid $black;

        .contents {
            max-height: 240px;
            overflow-y: scroll;

            p {
                margin-bottom: 5px; } }

        .advert img {
            height: 100%; } }

    aside {
        position: relative;
        height: 290px;
        overflow: hidden;

        .advert {
            max-width: 150px;

            img {
                height: 100%; } }

        header {
            position: relative;
            background: 0 0;
            z-index: initial;
            width: calc(100% - 150px);

            .live-icon {
                border: 1px solid $black;
                background-color: $red;
                padding: 3px 5px;
                border-radius: 10px;
                color: $white;
                margin: 20px 10px 0 0; } }

        main {
            width: calc(100% - 150px);

            h5 {
                margin-bottom: 0;
                margin-top: 3px; } }

        footer {
            position: absolute;
            bottom: 0;
            width: calc(100% - 150px);
            text-align: center;
            border-top: 1px solid $black;
            padding: 5px;

            p {
                text-align: center;
                margin-bottom: 5px;

                span {
                    color: $violate;
                    border-bottom: 1px solid $black; } }

            img {
                max-width: 50px; } } } }

.detail {

    h1 {
        font-size: 18px;
        margin: 5px auto 10px;
        font-weight: 600; }

    .author-date {
        border-bottom: 1px solid $black; }

    .author {
        border-right: 1px solid $green; }

    .social-links {

        p img {
            max-height: 15px; } } }

.disclaimer {
    border: 1px solid $green;
    position: relative;
    padding: 5px 75px;

    &:before {
        position: absolute;
        content: " ";
        width: 70px;
        height: 100%;
        top: 0;
        left: 0;
        background: transparent url('/public/img/theme/disclaimer-border-left.png') no-repeat;
        -webkit-background-size: cover;
        background-size: cover; }

    &:after {
        position: absolute;
        content: " ";
        width: 70px;
        height: 100%;
        top: 0;
        right: 0;
        background: transparent url('/public/img/theme/disclaimer-border-right.png') no-repeat;
        -webkit-background-size: cover;
        background-size: cover; }

    p {
        font-size: 12px;
        text-align: center;
        margin: 0px; } }

.rating-summary {
    margin: 0 auto;
    height: 32px;
    align-items: center;
    padding: 2px 10px;
    display: flex;
    justify-content: space-between;

    .btn {
        padding: 3px 5px; } }

.type-comment {
    height: 150px;
    border: 2px solid $gray;
    margin-top: 4px; }

.comment-address {
    display: flex;
    background: 0 0;
    padding: 5px 60px 5px 0px;
    justify-content: flex-end;

    .form-control {
        max-width: 220px;
        margin-right: 10px;
        border: 2px solid $gray; }

    img {
        max-width: 35px;
        margin-right: 5px; } }

.user-comment-preheading {
    border-bottom: 2px solid $gray; }

.comments {

    .comment {

        .likes {

            a {
                display: block;

                span {
                    background-color: $lightGray;
                    width: 70px;
                    padding: 1px 5px;
                    display: inline-block; } } } } }

p.strong {
    font-weight: 600;
    font-size: 12px;
    line-height: 1.3; }

.single-slider.slick-slider {

    .slick-next, .slick-prev {
        top: 60%;
        background-color: transparent;
        background-repeat: no-repeat;
        height: 31px;
        width: 17px;
        background-size: cover;
        z-index: 1;

        &:before {
            content: "";
            opacity: 0;
            display: none; } }

    .slick-next {
        right: -3px;
        background-image: url('/public/img/theme/right-arrow.png'); }

    .slick-prev {
        left: -3px;
        background-image: url('/public/img/theme/left-arrow.png'); } }

.get-more {
    position: relative;

    &:before {
        content: " ";
        position: absolute;
        top: calc(50% - 1px);
        left: 0;
        height: 2px;
        width: calc(100% - 5px);
        background-color: $blue; } }

.nav-area {

    .navbar-default {

        .navbar-header {

            a {
                padding: 5px;
                height: 34px; }

            i {
                color: $white; } }

        .navbar-nav {

            li {

                a {
                    border: 1px solid #A25922;
                    border-radius: 5px;
                    padding: 5px;
                    margin: 2px;
                    background-color: #F9B81E;
                    color: #000;
                    font-weight: 600; } } } } }

.news-slider {
    position: relative;

    .figure {
        background-color: rgba(255,255,255,0.5);
        border: 1px solid $black;
        padding: 10px 0 10px 10px; }

    figure {
        background-color: #FFF0BD; }

    .slick-next, .slick-prev {
        top: 50%;
        width: auto;
        height: auto; }

    .slick-next:before, .slick-prev:before {
        width: 32px;
        font-size: 60px !important;
        display: inline-block;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: $red; }

    .slick-next:before {
        content: "\f105"; }

    .slick-prev:before {
        content: "\f104"; } }

.most-read {

    #latest-list.fade.in.active {
        display: block; }

    #latest-list.fade {
        display: none; }


    .latest {
        border: 2px solid $gray; }

    .make-scroll {
        margin: 0;

        a {
            font-size: 22px;
            margin: 5px 0;
            line-height: 1; } }

    ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        padding-left: 25px;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        max-height: 400px;

        span, ul {
            display: flex;
            flex-wrap: wrap;
            flex: 1; }

        li.btn {
            display: flex;
            align-items: center; }

        li {
            margin: 0;
            padding: 0;
            list-style: none;

            a {
                height: auto;
                display: flex;
                margin: 5px;

                img {
                    max-width: 75px;
                    max-height: 40px; }

                span {
                    background-color: rgba(255,255,255, 0.5);
                    padding: 5px; } } } }

    .preview {
        padding-right: 0px;

        .light-gray-border {
            border: 2px solid $lightGray; }

        img {
            width: 100%; }

        header {
            padding: 5px 10px;

            &.no-pull {
                padding: 0px; }

            h4 {
                font-size: 14px; }

            h5 {
                font-size: 12px; }

            h3 {
                font-size: 22px;
                margin-top: 0px; } }

        p {
            padding: 7px 10px; }

        .btn {

            img {
                width: 108px;
                height: 22px;
                margin-left: 5px; } }

        .sidelist {
            padding-right: 0px;
            max-height: 470px;
            overflow-y: scroll;

            ul {
                display: block;
                max-height: 470px;

                li {

                    a {
                        background-color: rgba(255,255,255, 0.5);
                        padding: 0px;
                        font-weight: 600;
                        margin-bottom: 5px;
                        display: inline-block;

                        &:hover {
                            text-decoration: none; }

                        img {
                            width: 100%;
                            max-width: 100%;
                            height: auto;
                            max-height: 100%; }

                        p {
                            padding: 0px; } } } } } } }

.banner-ads {

    .ad {
        height: 100px;
        background-color: $blue;

        img {
            height: 100%;
            width: 100%; } } }

.most-read {

    ul.tab-list, ul.tab-list ul {
        padding-left: 0px;
        width: auto; } }

.tab-list {
    border-bottom: 2px solid $orange;
    overflow: hidden;

    li.btn {
        background-color: $orange;
        padding: 5px;

        &.active {

            a.btn {
                border-bottom: 2px solid $red; } } }

    a.btn {
        background-color: $lightorange;
        font-weight: 600; }

    li.placeholder {
        background: $lightorange;
        width: 100%;
        margin: 5px; } }

.tabables {

    h5, p {
        overflow: hidden;

        a {
            font-size: 14px;
            font-weight: 600;
            min-height: 50px;
            line-height: 1.5;
            padding-right: 5px;
            display: block;

            &.btn {
                min-height: auto;
                display: initial; } } }

    p a {
        font-size: 12px; } }

.photo-slider {
    overflow: hidden;

    .slides {
        border-bottom: 5px solid #A8E1EA;

        .slide {
            position: relative;

            img {
                width: 100%; }

            .caption {
                position: absolute;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.5);
                width: 100%;
                font-size: 14px;
                margin: 0;
                padding: 5px 0; } } }

    .thumbs {
        background-color: $black;
        margin: 5px 30px;
        max-width: calc( 100% - 60px );
        max-height: 50px;

        .thumb {
            margin: 5px;
            padding-top: 3px;

            &.slick-current {
                border: 4px solid $white;
                padding: 0px;
                transition: all 0.5s fade-in-out; } }

        .slick-next:before, .slick-prev:before {
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            color: $green;
            font-size: 24px; }

        .slick-next:before {
            content: "\f0da"; }

        .slick-prev:before {
            content: "\f0d9"; } } }

.photo-gallery {
    max-height: 290px;
    overflow-y: scroll;
    margin-bottom: 5px; }

footer {

    img {
        margin: 0 auto; }

    .holder {
        background: transparent url("/public/img/theme/footer.png") no-repeat center center;
        -webkit-background-size: cover;
        background-size: cover;
        padding: 10px 0;
        display: flex; }

    .holder > div {
        padding: 0 3px;
        flex: 1;

        &:first-child {
            max-width: 235px; }

        &:last-child {
            min-width: 320px; } }

    .links {
        margin: 10px 0;
        display: flex;
        justify-content: space-around;
        padding: 0 10%; }

    p {
        font-size: 11px;
        margin-bottom: 2px;
        line-height: 1;

        .btn-rounded {
            border-radius: 10px; } } }

.aro-jante {
        position: relative;
        display: list-item;
        padding-left: 30px;

        &:before {
            content: " ";
            background: transparent url('/public/img/theme/aro.png') no-repeat;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 30px;
            background-position: 0 0;
            background-size: contain; }

        li {
            font-size: 12px;

            &:before {
                display: inline-block;
                font: normal normal normal 14px/1 FontAwesome;
                font-size: 10px;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "\f111";
                color: $navy; }

            a:hover {
                text-decoration: none; } } }

.right-sidebar {

    .more {
        text-align: center;

        img {
            border-radius: 13px; } }

    .advert {

        img {
            width: 100%; } }

    .shompadokiyo {
        background-color: #FDF4F7;

        .label {

            img {
                width: 100%; } }

        h4 {
            font-size: 14px;
            line-height: 1.3; } }


    .interview {

        .more {
            margin-top: -5px;
            text-align: center; }

        header {
            position: relative;
            background: 0 0;

            img {
                margin: 0 auto; } }

        .holder {
            border-radius: 40px;
            border: 2px solid $blue;
            overflow: hidden; }

        article {
            background-color: #F1F6D5;
            font-size: 12px; }

        h3 {
            border-top: 2px solid $blue;
            border-bottom: 2px solid $blue;
            font-size: 14px;
            padding: 5px;
            margin: 5px auto; }

        h4 {
            font-size: 10px;
            background: rgb(255,255,255);
            background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(168,225,234,1) 100%);
            background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(168,225,234,1) 100%);
            background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(168,225,234,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#a8e1ea',GradientType=1 ); } }

    .career {

        p {
            display: flex; }

        h4 {
            font-size: 12px; }

        img {
            margin-top: 10%; } }

    .opinion {

        h4 {
            font-size: 14px;
            background: #A2D3AD url(/public/img/theme/opinion-underline.png) no-repeat;
            background-position: 0 100%;
            padding-bottom: 20px;
            background-size: contain;
            margin-top: 0px;

            img {
                width: 100%; } }

        .underline {
            color: $blue;
            border-bottom: 1px solid $blue; } }

    .question {

        h4 {

            img {
                width: 100%; } }

        .q {
            background-color: #FFF3E3;
            border-top: 2px solid $green;
            border-bottom: 2px solid $green; }

        .choices {

            span {
                position: relative;
                margin-right: 10px;

                &:hover {
                    cursor: pointer; }

                .fa {
                    font-size: 12px;
                    color: $black; }

                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    opacity: 0; } } }

        .process {
            background-color: #A42189;

            .btn-rounded {
                border-radius: 15px; } }

        .result {

            p {
                display: block;
                padding: 5px;

                .stat {
                    width: 50px;
                    display: inline-block; }

                .progress-range {
                    background-color: $red;
                    height: 7px;
                    width: 10%;
                    display: inline-block; } }

            .last-result {
                margin: 0;
                padding: 0;

                img {
                    width: 100%; } } } }

    .news-summary {

        h4 {
            font-size: 14px; } }

    .old-version {

        img {
            width: 100%; }

        .choices {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            position: relative;
            padding: 5px 0;

            span.label {
                width: 75px; }

            ul {
                width: calc( 100% - 75px );
                display: flex;
                flex-wrap: wrap;
                padding-left: 22px;
                justify-content: flex-end;

                li {
                    position: relative;
                    margin-left: -22px;
                    border-radius: 20px 0 0 0;
                    padding-top: 2px;
                    height: 23px;
                    overflow: hidden;

                    select {
                        background: 0 0;
                        border: 0px transparent; }

                    .fa {
                        position: absolute;
                        right: 22px;
                        top: 5px; } }

                .day {
                    background-color: $navy;

                    select {
                        width: 57px; } }

                .month {
                    background-color: $orange;

                    select {
                        width: 90px; } }

                .year {
                    background-color: #D7E164;

                    select {
                        width: 72px; } }

                .submit {
                    background-color: $green;
                    padding: 1px 5px;

                    button {
                        background: 0 0;
                        color: $white;
                        border: 0px transparent; } } } } }

    .quize {

        .date {
            background: transparent url(/public/img/theme/quize-heading.png) no-repeat;
            background-size: cover;
            position: relative;
            overflow: hidden;
            height: 40px;

            span {
                background: rgb(11,176,162);
                background: -moz-linear-gradient(left,  rgba(11,176,162,1) 0%, rgba(255,255,255,1) 34%, rgba(255,255,255,1) 71%, rgba(11,176,162,1) 100%);
                background: -webkit-linear-gradient(left,  rgba(11,176,162,1) 0%,rgba(255,255,255,1) 34%,rgba(255,255,255,1) 71%,rgba(11,176,162,1) 100%);
                background: linear-gradient(to right,  rgba(11,176,162,1) 0%,rgba(255,255,255,1) 34%,rgba(255,255,255,1) 71%,rgba(11,176,162,1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0bb0a2', endColorstr='#0bb0a2',GradientType=1 );
                padding: 3px 5px;
                position: absolute;
                right: 10px;
                top: 10px;
                font-size: 10px; } }

        h4 {
            background: transparent url('/public/img/theme/quize-heading-bg2.png') no-repeat;
            background-size: 100% 45px;
            background-position: center center;

            a {
                font-size: 14px;
                font-weight: 600;

                &.btn {
                    border-radius: 15px;
                    padding: 2px 7px;
                    font-size: 12px;
                    margin: 2px 7px 2px 0;
                    border: 1px solid $blue; } } }

        .questions {

            .question {
                font-size: 12px;

                .q {
                    border: 0px transparent;
                    background-color: transparent;
                    margin-bottom: 0px; } } }

        .contact {
            background-color: #D1E9DB;

            .col-xs-6 {
                display: inline-flex; }

            label {
                font-size: 10px;
                font-weight: 400;
                color: $green; }

            .form-control {
                font-size: 12px;
                border-radius: 0px;
                border: 1px solid $black;
                background: 0 0;
                height: 18px; } } }

    .stockexchange {
        background-color: rgba(255,255,255, 0.5);

        h4 {
            background-color: #8C3323;
            color: $white;
            text-align: center;
            padding: 5px 0px;
            margin: 0; }

        ul {
            display: list-item;
            padding: 5px 0;

            li {
                list-style-type: disc;
                list-style-position: inside; } } }

    .money-exchange {

        h4 {
            background-color: #91278B;
            color: $white;
            text-align: center;
            padding: 5px 0px;
            margin: 0; }

        table {

            thead {
                background-color: #FEE9EE; }

            td, th {
                border: 1px solid $black;
                text-align: center;
                font-size: 12px; } } }

    .prayer {
        border: 2px solid $green;
        border-radius: 5px;
        font-size: 11px;

        img {
            width: 100%; }

        h4 {
            font-size: 12px;
            padding: 0px;
            margin-top: 0px;
            margin-bottom: 5px;
            border-top: 2px solid $green;
            border-bottom: 2px solid $green;

            .btn:last-child {
                font-size: 10px;
                padding-top: 7px;
                text-align: center; } }

        .prayer-time {
            background-color: #F1F7DD;

            &.table {

                tr td {
                    border: 1px solid $black;
                    padding: 3px;
                    font-size: 10px; } } }

        .sun {

            .btn {
                font-size: 10px;
                padding: 5px 2px;

                &:first-child {
                    border-right: 1px solid $black; } } }

        .sehri.table {

            tr {
                td {
                    border: 1px solid $black;
                    padding: 3px;
                    font-size: 10px; }

                &:first-child {
                    border-radius: 5px 0 0 0; }

                &:last-child {
                    border-radius: 0 5px 0 0; } } } }

    .shok {
        display: flex;

        img {
            width: 100%;
            height: 100%; }

        .detail {
            border-top: 13px solid #A46504;
            border-bottom: 13px solid #A46504;

            h6 {
                padding: 0px 10px;
                font-size: 20px;
                line-height: 1.5; } } }

    .rashi {
        border: 1px solid $green;

        img {
            width: 100%; }

        .holder {
            border: 1px solid $green;
            border-radius: 5px;
            margin: 0 0 0 5px;

            h3 {
                font-size: 20px;
                padding: 0 10px;
                margin-top: 7px; } }

        .btn {
            font-size: 9px;
            padding: 3px 0px; } } }

.pagination {
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;

    li {

        a {
            background-color: $red;
            color: $white;
            border: 0px transparent;
            margin: 20px 2px 10px !important;
            padding: 5px 10px;
            font-size: 20px; }

        &.active {

            a {
                background-color: $orange; } }

        &:first-child, &:last-child {

            a {
                background-color: transparent;
                color: $black;
                font-size: 24px;
                line-height: 1;

                i {
                    font-size: 26px;
                    margin: 0px; } } } } }

.breadcrumb {
    background-color: $navy;
    padding: 0 0 0 5px;
    overflow: hidden;

    li {
        padding: 0px;
        margin: 0px;
        position: relative;

        &:before {
            content: "";
            opacity: 0;
            display: none; }

        &:after {
            border-style: solid;
            border-width: 19px 0 19px 15px;
            content: " ";
            position: absolute;
            left: 100%;
            top: 0;
            z-index: 1;
            border-color: transparent transparent transparent $white; }

        &:last-child a:after {
            left: 100%; }

        &:first-child {
            margin-left: 0px;

            a {
                border-radius: 0px; } }

        a {
            color: $white;
            position: relative;
            height: 38px;
            display: inline-flex;
            font-size: 14px;
            padding: 0px 5px;
            margin: 0px;
            margin-left: -5px;
            padding-left: 15px;
            align-items: center;
            justify-content: flex-end;

            &:hover {
                text-decoration: none; }


            &:after {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 19px 0 19px 15px;
                content: " ";
                position: absolute;
                left: calc(100% - 2px);
                top: 0;
                z-index: 2; }

            &.blue {
                background-color: $blue;
                &:after {
                    border-color: transparent transparent transparent $blue; } }

            &.red {
                background-color: $red;
                &:after {
                    border-color: transparent transparent transparent $red; } }

            &.pink {
                background-color: $pink;
                &:after {
                    border-color: transparent transparent transparent $pink; } }

            &.green {
                background-color: $green;
                &:after {
                    border-color: transparent transparent transparent $green; } } } } }

.latest.top10 {

    li {

        a {

            img {
                max-height: 45px; } }

        span {
            width: 100%;
            font-size: 14px;
            background-color: transparent;
            border-bottom: 2px solid $gray; } } }

@media screen and (max-width: 768px) {

    @import "800.sass"; }
